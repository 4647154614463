import React from "react"

import {
  TeamWrapper,
  TeamContainer,
  Heading,
  TeamCardContainer,
  TeamCard,
  TeamImg,
  FullName,
  Title,
  Delimiter,
  Description,
} from "./TeamElements"

const TeamSection = ({ employees }) => {
employees.sort((a,b) => {
  const currLastName = a.name.split(" ").slice(-1).join(" ")
  const nextLastName = b.name.split(" ").slice(-1).join(" ")
  if(currLastName < nextLastName) return -1
  if(currLastName > nextLastName) return 1
  return 0
});
  return (
    <TeamWrapper id="team">
      <TeamContainer>
        <Heading>Här är vi som gillar att utveckla och utvecklas</Heading>
        <TeamCardContainer>
          {employees.map(employee => (
            <TeamCard key={employee.id}>
              <TeamImg src={employee.image.file.url} />
              <FullName> {employee.name} </FullName>
              <Title> {employee.title} </Title>
              <Delimiter></Delimiter>
              <Description> {employee.description.description} </Description>
            </TeamCard>
          ))}
        </TeamCardContainer>
      </TeamContainer>
    </TeamWrapper>
  )
}

export default TeamSection
