import * as React from "react"
import { useState } from "react"
import { GlobalStyles } from "../components/GlobalStyles"
import Seo from "../components/seo"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import { HeaderSection } from "../components/HeaderSection"
import AboutSection from "../components/AboutSection"
import TeamSection from "../components/TeamSection"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
import CookieBanner from "../components/CookieBanner"

const IndexPage = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <GlobalStyles />
      <CookieBanner />
      <Seo title="Sprinto - Vi utvecklar teknik för människor" />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeaderSection />
      <AboutSection />
      <TeamSection
        employees={data.allContentfulSprintoEmployee.edges.map(
          item => item.node
        )}
      />
      <Footer />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    allContentfulSprintoEmployee {
      edges {
        node {
          id
          name
          title
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
