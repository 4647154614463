import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useState } from "react"
import { Button } from "../ButtonElement"
import { Modal } from "../Modal"
import Typewriter from "./Typewriter"
import {
  AboutWrapper,
  AboutContainer,
  GridContainer,
  ContentContainer,
  TextContainer,
  Heading,
  DescriptionContent,
  Contact,
  ImgContainer,
  Img,
} from "./InfoElements"

const InfoSection = () => {
  const allMessages = [
    "snälla",
    "prestigelösa",
    "hjälpsamma",
    "engagerade",
    "kreativa",
    "pedagogiska",
    "orädda",
    "sociala",
  ]
  const [showModal, setShowModal] = useState(false)

  const { contentfulSprintoAbout } = useStaticQuery(graphql`
  query AboutQuery {
    contentfulSprintoAbout {
    contentfulid
    heading
    descriptionPartOne
    descriptionPartTwo {
      descriptionPartTwo
    }
    descriptionPartThree
    buttonLabel
    img {
      file {
        url
      }
    }
    isActive
  }
}
`)

  //Ändrar state från förgående, för att toggla modalen
  const openModal = () => {
    setShowModal(prev => !prev)
  }

  return (
    <>
      <AboutWrapper id={contentfulSprintoAbout.contentfulid}>
        <AboutContainer>
          <GridContainer>
            <ContentContainer>
              <TextContainer>
                <Heading>{contentfulSprintoAbout.heading} </Heading>
                <DescriptionContent>
                  {contentfulSprintoAbout.descriptionPartOne} {<br />} {<br />} {contentfulSprintoAbout.descriptionPartTwo.descriptionPartTwo}
                  {<br />} {<br />} {contentfulSprintoAbout.descriptionPartThree}
                </DescriptionContent>
                <Contact>
                  Vi söker alltid efter
                  <Typewriter words={allMessages}> </Typewriter>
                </Contact>
                <Contact>kollegor. Ska vi ta en fika? </Contact>
                <Button isActive={contentfulSprintoAbout.isActive} onClick={openModal}>
                  {contentfulSprintoAbout.buttonLabel}
                </Button>
                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              </TextContainer>
            </ContentContainer>
            <ContentContainer>
              <ImgContainer>
                <Img src={contentfulSprintoAbout.img.file.url} alt={contentfulSprintoAbout.alt} />
              </ImgContainer>
            </ContentContainer>
          </GridContainer>
        </AboutContainer>
      </AboutWrapper>
    </>
  )
}

export default InfoSection
